import _ from 'lodash'
import { createSelector } from 'reselect'

import { PENDING, SUCCESS } from '../../reduxPromise'

export const getIsLoading = (state) => _.get(state, 'config.status') === PENDING
export const getError = (state) => _.get(state, 'config.error')
export const getWebsiteConfig = state => _.get(state, 'config.websiteConfig')
export const getAgreeTerms = state => _.get(state, 'config.agreeTerms')

export const getIsLoggedIn = state => _.get(state, 'auth.user')
export const getCurrentOrganisationDetails = state => _.get(state, 'organisationDetails.organisation.details')

export const getIsMandatoryTerms = createSelector([
  getCurrentOrganisationDetails
], (orgDetails) => {
  return _.get(orgDetails, 'mandatoryTerms', false)
})

export const showTermsOfUse = createSelector([
  getIsLoggedIn,
  getIsMandatoryTerms
], (isLoggedIn, mandatoryTerms) => {
  if (mandatoryTerms && !isLoggedIn) return true
  return false
})

export const getWebsiteConfiguration = createSelector([
    getWebsiteConfig
  ], (webConfig) => {
    return webConfig
})

export const getNodeEnv = createSelector([
  getWebsiteConfig
], (webConfig) => _.get(webConfig, 'nodeEnv', ''))

export const getIsProduction = createSelector([
  getNodeEnv
], nodeEnv => nodeEnv === 'production'
)

export const getSource = state => state.config?.websiteConfig?.vigilanceHubSource
export const getOIDCCookie = state => state.config?.websiteConfig?.OIDCCookieKey
